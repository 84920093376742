import React from 'react';
import './Announce.css';

const Announce = ({ text }) => {
  return (
    <div className="announcement-container">
      <p className="announcement-text">
        {text || " "}
      </p>
    </div>
  );
};

export default Announce;
